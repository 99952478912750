<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
        <component
          :key="material.key"
          :is="material.component"
          :param.sync="param"
        />
        <component
          :key="bom.key"
          :is="bom.component"
          :param.sync="param"
        />
        <component
          :key="drawing.key"
          :is="drawing.component"
          :param.sync="param"
        />
    </div>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import { uid } from 'quasar'
export default {
  name: 'equipment-etc',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
        equipmentTypeCd: '',
      }),
    },
  },
  data() {
    return {
      data: {
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
        equipmentTypeCd: '',
      },
      material: {},
      bom: {},
      drawing: {},
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.getDetail();
    },
    getDetail() {
      if (this.param.equipmentCd) {
        this.$http.url = selectConfig.mdm.equipment.get.url;
        this.$http.param = {equipmentCd: this.param.equipmentCd, plantCd: this.param.plantCd}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.param.plantName = _result.data.plantName;
          this.param.plantCd = _result.data.plantCd;
          this.param.equipmentCd = _result.data.equipmentCd;
          this.param.equipmentNo = _result.data.equipmentNo;
          this.param.equipmentTypeCd = _result.data.equipmentTypeCd;
          this.param.equipmentTypeName = _result.data.equipmentTypeName;
          this.param.equipmentName = _result.data.equipmentName;
          this.param.processCds = _result.data.processCds;
          // this.material = {
          //   component: () => import(`${'./equipmentMaterial.vue'}`), key: uid(),
          // }
          this.bom = {
            component: () => import(`${'./equipmentBom.vue'}`), key: uid(),
          }
          this.drawing = {
            component: () => import(`${'./equipmentDrawing.vue'}`), key: uid(),
          }
        },);
      }
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>