var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      {
        staticClass:
          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical",
      },
      [
        _c(_vm.material.component, {
          key: _vm.material.key,
          tag: "component",
          attrs: { param: _vm.param },
          on: {
            "update:param": function ($event) {
              _vm.param = $event
            },
          },
        }),
        _c(_vm.bom.component, {
          key: _vm.bom.key,
          tag: "component",
          attrs: { param: _vm.param },
          on: {
            "update:param": function ($event) {
              _vm.param = $event
            },
          },
        }),
        _c(_vm.drawing.component, {
          key: _vm.drawing.key,
          tag: "component",
          attrs: { param: _vm.param },
          on: {
            "update:param": function ($event) {
              _vm.param = $event
            },
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "popup-bottom-bar" }, [
      _c(
        "div",
        { staticClass: "popup-bottom-bar-close" },
        [
          _c("q-btn", {
            attrs: { flat: "", color: "gray", icon: "arrow_back" },
            on: { click: _vm.closePopUps },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }